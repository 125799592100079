import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react"
import { parseDigit } from "input-format"
import ReactInput from "input-format/react"
import { AsYouType, CountryCode } from "libphonenumber-js"
import React, { forwardRef, useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useDebounce } from "react-use"

interface Props extends InputProps {
  countryCode: CountryCode
  label: string
  name: string
  errors: any
  inputSize?: string
}

const PhoneInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      countryCode = "US",
      label,
      name,
      defaultValue = "",
      onBlur,
      inputSize = "md",
      ...rest
    },
    ref
  ) => {
    const {
      setValue,
      formState: { errors, dirtyFields, touchedFields },
    } = useFormContext()

    const isInvalid = Boolean(errors[name])
    const isDirty = dirtyFields[name] || touchedFields[name]

    const [inputValue, setInputValue] = useState<string>(String(defaultValue))

    useEffect(() => {
      if (!isDirty) {
        setInputValue(String(defaultValue))
      }
    }, [setInputValue, defaultValue, isDirty])

    const parser = useCallback((character: string, value: string) => {
      if (character === "+" && !value) {
        return character
      }
      return parseDigit(character)
    }, [])

    const formatter = useCallback(
      (value: string) => {
        const asYouType = new AsYouType(countryCode)
        const newValue = asYouType.input(value)
        const template = asYouType.getTemplate()

        return { template, text: newValue }
      },
      [countryCode]
    )

    useDebounce(
      () => {
        setValue(name, inputValue.replace(/\D/g, ""), {
          shouldDirty: true,
          shouldTouch: true,
        })
      },
      500,
      [inputValue]
    )

    return (
      <FormControl isInvalid={isInvalid}>
        {label && <FormLabel fontWeight="normal">{label}</FormLabel>}

        <Input
          data-testid="phone-input"
          id="p-checkout-phone-number"
          autoComplete="tel"
          size={inputSize}
          borderRadius="md"
          as={ReactInput}
          isInvalid={isInvalid}
          value={inputValue}
          onChange={setInputValue}
          parse={parser}
          format={formatter}
          type="text"
        />

        <Input type="hidden" ref={ref} name={name} {...rest} />

        {isInvalid && (
          <FormErrorMessage>{errors[name].message}</FormErrorMessage>
        )}
      </FormControl>
    )
  }
)

export default PhoneInput

import {
  Button,
  InputProps,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ConfirmationModal from "~/components/ConfirmationModal"
import { useAuth } from "~/hooks/useAuth"
import { useCoupon } from "~/hooks/useCoupon"
import FormInput from "./FormInput"

interface Props extends InputProps {}

const EmailInput: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation()

  const {
    register,
    getValues,
    resetField,
    formState: { errors },
    getFieldState,
  } = useFormContext()

  const emailValue = getValues("email")
  const { isDirty } = getFieldState("email")

  const { couponIsLoading } = useCoupon({ isEmailFieldDirty: isDirty })

  useEffect(() => {
    if (couponIsLoading) {
      resetField("email", { defaultValue: emailValue })
    }
  }, [couponIsLoading, emailValue, resetField])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    state: { isAuthenticated },
    logout,
  } = useAuth()

  const changeButton = isAuthenticated ? (
    <InputRightElement width={20}>
      <Button id="change-email-button" data-testid="change-button" size="xs" onClick={onOpen}>
        {t("components.checkout.fields.EmailInput.Change")}
      </Button>
    </InputRightElement>
  ) : null

  return (
    <>
      <FormInput
        label={t("components.checkout.fields.EmailInput.Input")}
        type="email"
        autoComplete="email"
        errors={errors}
        defaultValue={emailValue}
        paddingRight={isAuthenticated ? 20 : 4}
        isTruncated={isAuthenticated}
        isDisabled={isAuthenticated}
        {...register("email", {
          required: true,
        })}
        rightEl={changeButton}
        {...props}
      />

      {isAuthenticated && (
        <ConfirmationModal
          title={t("components.checkout.fields.EmailInput.Confirmation.Title")}
          description={t(
            "components.checkout.fields.EmailInput.Confirmation.Description"
          )}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => logout()}
        />
      )}
    </>
  )
}

export default EmailInput

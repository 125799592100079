import { Flex } from "@chakra-ui/react"
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useCart } from "~/hooks/useCart"
import { PaymentFields } from "~/interfaces/entities/Payment"
import {
  CheckoutFormContext,
  CheckoutFormContextInterface,
} from "../../blocks/CheckoutBlock"
import TermsInput from "../fields/TermsInput"
import FormAction from "../FormAction"
import { onEnterKeyFocusNext } from "../FormFocusHelper"
import FormHeading from "../FormHeading"
import FormStack from "../FormStack"
import { PAYMENT_METHOD_KEY, usePaymentOption } from "./hooks/usePaymentOption"
import PaymentFormWatcher from "./PaymentFormWatcher"
import PaymentSelector from "./PaymentSelector"
import { useStripePayment } from "./stripe/hooks/useStripePayment"

export interface PickupFormFields {
  fullName: string
  email: string
  phoneNumber: string
  country: string
  payment: PaymentFields | null
}

export type PickupFormDefaultFields = {
  [key: string]: string
} & Partial<PickupFormFields>

interface Props {
  FormDetails: FunctionComponent<any>
  fields: string[]
  defaultValues: PickupFormDefaultFields
  orderSuccessPath: string
  onSubmit: (data: any) => void
  onChange: (data: any) => void
  isLoading: boolean
  isDisabled: boolean
  isReady: boolean
}

const PaymentFormDesktop: React.FC<Props> = ({
  onSubmit,
  onChange,
  isLoading,
  isDisabled,
  isReady,
  defaultValues,
  orderSuccessPath,
  fields,
  FormDetails,
}) => {
  const { t } = useTranslation()
  const [isPaymentSelectorActive, setIsPaymentSelectorActive] = useState(false)
  const formHooks = useForm<PickupFormFields>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, touchedFields, errors, isSubmitSuccessful },
    reset,
    setValue,
    getValues,
  } = formHooks

  const {
    cart: { paymentIntent: { clientSecret } = {} },
  } = useCart()

  const {
    isLoading: isPaymentProcessing,
    onCardPaymentSubmit: onStripeSubmit,
    errorMessage: paymentError,
  } = useStripePayment(orderSuccessPath, clientSecret)

  const { option, hasPayInStore, hasStripe, isFree } = usePaymentOption()

  const isPaymentReady = Boolean(clientSecret) || isFree

  useEffect(() => {
    if (hasPayInStore || hasStripe) {
      setValue(PAYMENT_METHOD_KEY, option)
    }
  }, [hasPayInStore, hasStripe, option, setValue])

  useEffect(() => {
    if (clientSecret) {
      setIsPaymentSelectorActive(true)
    } else {
      setIsPaymentSelectorActive(false)
    }
  }, [clientSecret])

  useEffect(() => {
    reset({
      ...getValues(),
      ...defaultValues,
    })
  }, [JSON.stringify(defaultValues), getValues, reset])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
      setIsPaymentSelectorActive(true)
    }
  }, [getValues, isSubmitSuccessful, reset])

  const { fieldsList } =
    useContext<CheckoutFormContextInterface>(CheckoutFormContext)

  const enhancedOnSubmit = (data: PickupFormFields) => {
    if (clientSecret) {
      onStripeSubmit()
    } else {
      setIsPaymentSelectorActive(true)
      onSubmit(data)
    }
  }

  return (
    <FormProvider {...formHooks}>
      <Flex
        direction="column"
        flex={1}
        as="form"
        onSubmit={handleSubmit(enhancedOnSubmit)}
        onKeyDown={e =>
          onEnterKeyFocusNext(
            e,
            getValues(),
            dirtyFields,
            touchedFields,
            errors,
            fieldsList,
            true
          )
        }
      >
        <FormHeading>{t("components.checkout.PickupForm.Heading")}</FormHeading>
        <FormDetails control={control} />

        <FormStack>
          {hasStripe && (
            <PaymentSelector
              isActive={isPaymentSelectorActive}
              errorMessage={paymentError}
            />
          )}
          {Boolean(hasPayInStore || isPaymentReady) && (
            <TermsInput name="terms" control={control} errors={errors} />
          )}
        </FormStack>
        <FormAction
          text={
            isPaymentReady
              ? t("components.checkout.FormAction.label.default")
              : t("components.checkout.DeliveryForm.Continue")
          }
          isLoading={isLoading || isPaymentProcessing}
          isDisabled={isDisabled}
          isReady={isReady}
        />
        <PaymentFormWatcher
          control={control}
          onChange={onChange}
          fields={fields}
        />
      </Flex>
    </FormProvider>
  )
}

export default PaymentFormDesktop
